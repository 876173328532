function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    const result = typeof title === 'function' ? title.call(vm) : title;
    return `${result} - Hug The Bugs - NSoC'22`;
  }
  return 'Hug The Bugs - NSoC\'22';
}
export default {
  created() {
    const title = getTitle(this);
    if (title) {
      document.title = title;
    }
  },
};

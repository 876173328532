/* eslint-disable */
<template>
  <div id="Home" class="min-h-screen flex items-center justify-flex-start flex-col mt-40">
    <h2>FIX ME :(</h2>
    <h2>&darr;</h2>
    <div id="questions">
      <span>
        <button
          @click="routeToQuestion(6)"
          type="button"
          class="btn btn-outline-secondary mt-5 mx-4 text-white"
          data-toggle="button"
          aria-pressed="false"
          autocomplete="off"
        >
          Demo Question
        </button>
        <br />
        <button
          @click="routeToQuestion(0)"
          type="button"
          class="btn btn-outline-success mt-5 mx-4 text-white"
          data-toggle="button"
          aria-pressed="false"
          autocomplete="off"
        >
          Question 1
        </button>
        <button
          @click="routeToQuestion(1)"
          type="button"
          class="btn btn-outline-success mt-5 mx-4 text-white"
          data-toggle="button"
          aria-pressed="false"
          autocomplete="off"
        >
          Question 2
        </button>
      </span>
      <br />
      <span>
        <button
          @click="routeToQuestion(2)"
          type="button"
          class="btn btn-outline-warning mt-5 mx-4 text-white"
          data-toggle="button"
          aria-pressed="false"
          autocomplete="off"
        >
          Question 3
        </button>
        <button
          @click="routeToQuestion(3)"
          type="button"
          class="btn btn-outline-warning mt-5 mx-4 text-white"
          data-toggle="button"
          aria-pressed="false"
          autocomplete="off"
        >
          Question 4
        </button>
        <button
          @click="routeToQuestion(4)"
          type="button"
          class="btn btn-outline-warning mt-5 mx-4 text-white"
          data-toggle="button"
          aria-pressed="false"
          autocomplete="off"
        >
          Question 5
        </button>
      </span>
      <br />
      <span>
        <button
          id="q6"
          @click="routeToQuestion(5)"
          type="button"
          class="btn btn-outline-danger mt-5 mx-4 text-white"
          data-toggle="button"
          aria-pressed="false"
          autocomplete="off"
        >
          Question 6
        </button>
      </span>
    </div>
    <!-- <div class="levels">
      <div id="easy">Easy</div>
      <div id="med">Med</div>
      <div id="hard">Hard</div>
    </div> -->
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import questions from '../func';

export default {
  name: 'HomeView',
  components: { Footer },
  data() {
    return {
      questions,
    };
  },
  methods: {
    routeToQuestion(index) {
      this.$router.push({
        name: 'question',
        params: {
          id: index,
        },
      });
    },
  },
  mounted() {},
};
</script>

<style>
h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  background: linear-gradient(121.57deg, #ffffff 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.levels {
  position: absolute;
  bottom: 70px;
  width: 30vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.levels > div {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

#easy {
  background: #5cb85c;
  color: white;
  font-weight: 700;
  animation: mover1 1s infinite alternate;
}
#med {
  background: #f0ad4e;
  color: white;
  font-weight: 700;
  animation: mover2 1s infinite alternate;
}
#hard {
  background: #d9534f;
  color: white;
  font-weight: 700;
  animation: mover3 1s infinite alternate;
}
@keyframes mover1 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-15px);
  }
}
@keyframes mover2 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-35px);
  }
}
@keyframes mover3 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-15px);
  }
}
</style>

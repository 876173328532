<!-- eslint-disable max-len -->
<template>
  <div id="app">
    <nav class="flex items-center justify-between w-full fixed top-0 left-0">
      <span style="display: flex">
        <img
          src="./assets/nisb-logo.png"
          alt=""
          style="height: 50px"
          class="mr-2 ms-4 h-6 mt-2 mb-2 logo-animate"
        />
        <img
          src="./assets/nie-logo.png"
          alt=""
          style="height: 50px"
          class="mr-2 ms-4 h-6 mt-2 mb-2 logo-animate"
        />
      </span>
      <Logo class="logo-text mr-4" v-if="$route.meta.nav !== false" />
    </nav>
    <router-view />
    <transition name="fade">
      <div
        id="loading"
        v-if="loading"
        class="min-h-screen w-full flex flex-col items-center justify-center fixed top-0 left-0 bg-white z-50"
      >
        <Logo class="mb-44" />
        <svg class="spinner" viewBox="0 0 50 50">
          <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/Logo.vue';

export default {
  data() {
    return {};
  },
  components: {
    Logo,
  },
  computed: {
    ...mapGetters({ loading: 'GET_LOADING' }),
  },
  created() {},
};
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  // background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
  background: linear-gradient(90deg, #db0b5f, #6f00ed);
}
body {
  // background-color: #1d1d1f !important;
  background-color: #0b141d !important;
}
h1 {
  font-weight: 700;
  font-family: Poppins, "sans-serif";
  background: linear-gradient(121.57deg, #000000 18.77%, rgba(0, 0, 0, 0.66) 60.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(35px);
  background: transparent;
}
.appear-enter-active,
.appear-leave-active {
  transition: all ease 0.5s;
}
.appear-enter,
.appear-leave-to {
  opacity: 0;
}
.conjure-enter-active,
.conjure-leave-active {
  transition: all 1s ease-in-out;
}
.conjure-enter,
.conjure-leave-to {
  opacity: 0;
  transform: translateY(35px);
  background: transparent;
}
.logo-animate {
  animation: conjure 2s ease-in-out 0s 1 alternate;
  width: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    user: null,
  },
  getters: {
    GET_LOADING: (state) => state.loading,
    GET_USER: (state) => state.user,
    GET_ANSWERED: (state) => state.user.answered,
    GET_END_TIME: (state) => state.endTime,
  },
  mutations: {
    SET_LOADING: (state, value) => {
      state.loading = value;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
  },
  actions: {},
  modules: {},
});

/* eslint-disable prefer-regex-literals */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-template */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
export const question1 = {
  name: 'something',
  difficulty: 'easy',
  fn: (input) => {
    if (input > 134218000) return 'Too large input';
    if (isNaN(input) || input <= 0) { return 'Invalid Input'; }
    if (input !== '11') { return 'Incorrect Guess'; }

    return 'x = 143';
  },
};

export const question2 = {
  name: 'something',
  difficulty: 'easy',
  fn: (input) => {
    if (isNaN(input)) { return 'Invalid Input'; }
    if (input !== '101') { return 'Incorrect Guess'; }
    return 'x = 5';
  },
};

export const question3 = {
  name: 'something',
  difficulty: 'medium',
  fn: (input) => {
    if (input !== 'egypt') { return 'Incorrect Guess'; }
    return 'x = 2';
  },
};

export const question4 = {
  name: 'something',
  difficulty: 'medium',
  fn: (input) => {
    if (input !== 'aryabhatta') { return 'Incorrect Guess'; }
    return 'x = 5';
  },
};

export const question5 = {
  name: 'something',
  difficulty: 'medium',
  fn: (input) => {
    if (isNaN(input) || input < 0) { return 'Invalid Input'; }
    if (input !== '1010') { return 'Incorrect Guess'; }
    return 'x = 0';
  },
};

export const question6 = {
  name: 'something',
  difficulty: 'difficult',
  fn: (input) => {
    if (input !== 'pascal') { return 'Incorrect Guess'; }
    return 'x = 3';
  },
};
export const question7 = {
  name: 'something',
  difficulty: 'easy',
  fn: (input) => {
    if (input !== 'pacific') { return 'Incorrect Guess'; }
    return 'x = 7';
  },
};

export default [question1, question2, question3, question4,
  question5, question6, question7];

<template>
  <div mb-1 color="dark" id="Logo" class="cursor-pointer" :class="{ 'logo-animate': animate }">
    <h1
      id="logo-title"
      class="w-max text-gray-800 flex flex-row items-center justify-center mb-1 ms-1"
      :class="{ 'text-4xl lg:text-6xl': size === 'md', 'text-xl lg:text-3xl': size === 'sm' }"
    >
      <img v-if="nisbLogo === true" src="@/assets/nisb-logo.png" alt="" class="h-6 mr-4" />
      Hug the Bugs - NSoC'22
    </h1>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Logo",
  props: {
    animate: {
      type: Boolean,
      default: true,
    },
    nisbLogo: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
h1#logo-title {
  font-weight: 700;
  font-size: 32px;
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  background: linear-gradient(121.57deg, #ffffff 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 72px;
}

@keyframes conjure {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      // requiresAuth: true,
      nav: true,
    },
  },
  {
    path: '/question/:id',
    name: 'question',
    component: () => import('../views/Question.vue'),
    meta: {
      requiresAuth: true,
      nav: true,
    },
  },
  // {
  //   path: '/sign-in',
  //   name: 'sign-in',
  //   component: () => import('../views/Signin.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     nav: false,
  //   },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
